import axios from 'axios'
import {
	API_ROOT_PROD,
	API_ROOT_TEST,
	CUSTOM_API_ROOT_PROD,
	CUSTOM_API_ROOT_TEST,
	API_PHOTO_UPLOAD_PROD,
	API_PHOTO_UPLOAD_TEST,
} from '../config'
// API_ROOT_TEST
// API_ROOT_PROD
const customMainApi = axios.create({
	baseURL:
		window.location.hostname.includes('credo-mobile-test') ||
		window.location.hostname.includes('localhost') ||
		window.location.hostname.includes('3000')
			? API_ROOT_TEST
			: API_ROOT_PROD,
	timeout: 100000,
	headers: {
		Accept: 'application/json',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'B1S-CaseInsensitive': true,
	},
	transformResponse: (data) => {
		return data
	},
	withCredentials: true,
})

customMainApi.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			// Redirect to the login page
			window.location.pathname = '/login'
		}
		return Promise.reject(error)
	},
)

export default customMainApi
// CUSTOM_API_ROOT_TEST
// CUSTOM_API_ROOT_PROD
const customFuncsApi = axios.create({
	baseURL:
		window.location.hostname.includes('credo-mobile-test') ||
		window.location.hostname.includes('localhost') ||
		window.location.hostname.includes('3000')
			? CUSTOM_API_ROOT_TEST
			: CUSTOM_API_ROOT_PROD,
	timeout: 100000,
	headers: {
		Accept: 'application/json',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'B1S-CaseInsensitive': true,
	},
	withCredentials: true,
})

customFuncsApi.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			window.location.pathname = '/login'
		}
		return Promise.reject(error)
	},
)
// API_PHOTO_UPLOAD_TEST
const customPhotoUploadApi = axios.create({
	baseURL:
		window.location.hostname.includes('credo-mobile-test') ||
		window.location.hostname.includes('localhost') ||
		window.location.hostname.includes('3000')
			? API_PHOTO_UPLOAD_TEST
			: API_PHOTO_UPLOAD_PROD,
	timeout: 100000,
	headers: {
		Accept: 'application/json',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'B1S-CaseInsensitive': true,
	},
	withCredentials: true,
})

customPhotoUploadApi.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			window.location.pathname = '/login'
		}
		return Promise.reject(error)
	},
)

export { customFuncsApi, customPhotoUploadApi }
